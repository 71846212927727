.page-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.hero {
  font-size: 1.85rem;
  display: block;
}

.hero.hero-sm {
  margin-top: 0.25rem;
  font-size: 1.5rem;
}
