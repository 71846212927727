.hero-container {
  padding: 1rem 0;
}

.hero-container .hero {
  font-size: 0.85rem;
  margin: 0;
}

.hero-container .hero.hero-sm {
  margin-top: 0.25rem;
  font-size: 0.75rem;
}

.search-bar-container .search-bar {
  padding-bottom: 0 !important;
}
