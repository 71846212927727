.region {
  display: block;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  margin-bottom: 0.5rem;
  padding: 0.24rem;
}
