.region {
  display: block;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  margin-bottom: 0.5rem;
  padding: 0.24rem;
}

.card {
  background-color: #f5f5f5;
}

.card-title {
  font-size: 1.25rem;
}

.card-image {
  aspect-ratio: 1 / 1;
  border-radius: 8px;
}
